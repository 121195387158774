/** External Dependencies **/
import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <path d="M-10-10h34v34h-34z" />
      <g stroke={color} strokeWidth="1.1">
        <path d="M13.304.381L.38 13.304M.38.381l12.924 12.923" />
      </g>
    </g>
  </svg>
);

Cross.propTypes = {
  color: PropTypes.string,
};

Cross.defaultProps = {
  color: '#61636A',
};

export default Cross;
