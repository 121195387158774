import Storage from './Storage';

/**
 * Representation of an access token just with null values.
 *
 * @type {{access_token: *, token_type: *, expires_in: *, refresh_token: *, created_at: *}}
 */
const nullToken = {
  access_token: null,
  token_type: null,
  expires_in: null,
  refresh_token: null,
  scope: null,
  created_at: null,
};

/**
 * AccessToken is an interface for saving and interacting with the user's
 * access token.
 */
class AccessToken {
  tokenStorageKey = 'zoToken';
  tokenStorageBackupKey = 'zoTokenBackup';
  storage: Storage;
  token;
  /**
   * Constructor
   */
  constructor(token = nullToken) {
    this.storage = new Storage();
    this.token = token;
  }

  /**
   * Checks if the access token has expired.
   *
   * @returns {boolean}
   */
  isExpired() {
    return this.expiredAt() <= Date.now() / 1000;
  }

  /**
   * Checks if the access token is NOT expired.
   *
   * @returns {boolean}
   */
  isNotExpired() {
    return !this.isExpired();
  }

  /**
   * Checks if the access token is NOT null.
   *
   * @returns {boolean}
   */
  isNotNull() {
    return this.get().access_token !== null;
  }

  /**
   * Checks if the access token is an authenticated token.
   *
   * @returns {boolean}
   */
  isAuthenticated() {
    return this.isNotNull() && this.isNotExpired();
  }

  /**
   * Checks if the token has a specific scope.
   *
   * @param {string} scope
   *
   * @returns {boolean}
   *
   * @TODO this needs to be double checked after further development.
   * @unstable
   */
  hasScope(scope) {
    if (!this.scope) {
      return false;
    }

    return this.scope.indexOf(scope) !== -1;
  }

  /**
   * Calculates the expired at time for the access token by adding the
   * created_at and expires_in properties.
   *
   * @returns {number}
   */
  expiredAt() {
    return this.token.created_at + this.token.expires_in;
  }

  /**
   * Get the token object.
   *
   * @returns {this.token}
   */
  get() {
    // If the in memory access_token is null, then we try to pull it from localStorage.
    if (this.token.access_token === null) {
      this.token = this.getFromStorage() || this.token;
    }

    return this.token;
  }

  /**
   * Get the backup token object.
   *
   * @returns {this.token}
   */
  getBackupToken() {
    return this.storage.get(this.tokenStorageBackupKey);
  }

  /**
   * Gets the token from storage.
   *
   * @returns {Object|null}
   *
   * @private
   */
  getFromStorage() {
    return this.storage.get(this.tokenStorageKey);
  }

  get accessToken() {
    return this.get().access_token;
  }

  get refreshToken() {
    return this.get().refresh_token;
  }

  get createdAt() {
    return this.get().created_at;
  }

  get expiresIn() {
    return this.get().expires_in;
  }

  get scope() {
    return this.get().scope;
  }

  get tokenType() {
    return this.get().token_type;
  }

  set(token) {
    this.token = token;
    this.persist();
  }

  /**
   * Sets a backup token and persist the token to Storage.
   *
   *
   * @param {Object}   token - The object representing the token.
   *
   * @returns {boolean | null}
   */
  setBackup(token) {
    return this.storage.set(this.tokenStorageBackupKey, token);
  }

  /**
   * Persists the token to Storage. When successfully persisting to Storage,
   * the function will return true. If it fails to persist to storage, null
   * is returned.
   *
   * @returns {boolean|null}
   */
  persist() {
    return this.storage.set(this.tokenStorageKey, this.token);
  }

  /**
   * Removes the token.
   */
  destroy() {
    this.token = nullToken;
    this.storage.remove(this.tokenStorageKey);
  }

  /**
   * Removes the backup token.
   */
  destroyBackup() {
    this.storage.remove(this.tokenStorageBackupKey);
  }
}

export default AccessToken;
