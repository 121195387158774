import React from 'react';
import ReactDOM from 'react-dom';
import 'immutable';
import 'sanitize.css/sanitize.css';
import 'react-select/dist/react-select.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-time-picker/assets/index.css';

/** Import copy and i18n messages **/
import { translationMessages } from './i18n';

/** Import Font and Global Sass Styles **/
import './fonts/source-sans-pro/source-sans-pro.scss';
import './index.scss';

/** Import routes **/
import Routes from './routes';

/** Mount App **/
const DOM_NODE = document.getElementById('app');
const render = (messages) => {
  ReactDOM.render(<Routes messages={messages} />, DOM_NODE);
};

/** Allow hot-reloading of copy/translation JSON files **/
if (module.hot) {
  module.hot.accept('./i18n', () => {
    render(translationMessages);
  });
}

/** Chunked polyfill for browsers without Intl support **/
let intlPromise;
if (window.Intl) {
  intlPromise = Promise.resolve();
} else {
  intlPromise = import('intl')
    .then(() =>
      Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/de.js')])
    )
    .catch((err) => {
      throw err;
    });
}
intlPromise.then(() => {
  render(translationMessages);

  /**
   * Polyfill time zones
   *
   * This should be run only after Intl has been polyfilled if appropriate.
   *
   * These are the minimal imports to cover the supported time zones and
   * languages. See the RegionForm component of the CMS.
   */
  /* eslint-disable global-require */
  require('date-time-format-timezone/build/src/date-time-format-timezone-no-data.js');
  require('date-time-format-timezone/build/src/data/metazone.js')(window);

  require('date-time-format-timezone/build/src/data/locales/locale-de.js')(window);
  require('date-time-format-timezone/build/src/data/locales/locale-en.js')(window);
  require('date-time-format-timezone/build/src/data/locales/locale-fr.js')(window);

  require('date-time-format-timezone/build/src/data/timezones/tzdata-europe-berlin.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-anchorage.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-chicago.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-new_york.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-pacific-honolulu.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-detroit.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-denver.js')(window);
  require('date-time-format-timezone/build/src/data/timezones/tzdata-america-los_angeles.js')(
    window
  );
  /* eslint-enable global-require */
});
