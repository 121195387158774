import SERVICES_ICON from '../images/icons/ic-services';
import PARTNERS_ICON from '../images/icons/ic-partners';
import REPORTS_ICON from '../images/icons/ic-reports';
import USERS_ICON from '../images/icons/ic-users';
import COMPANIES_ICON from '../images/icons/ic-companies';
import ADMINS_ICON from '../images/icons/ic-admins';
import CAMPUSES_ICON from '../images/icons/ic-campuses';
import REGIONS_ICON from '../images/icons/ic-regions';
import LOGOUT_ICON from '../images/icons/ic-logout';
import PromoCodeIcon from '../images/icons/ic-promocodes';
import SpacesIcon from '../images/icons/ic-spaces';

export const SERVICES = 'services';
export const PARTNERS = 'partners';
export const REPORTS = 'reports';
export const USERS = 'users';
export const COMPANIES = 'companies';
export const ADMINS = 'admins';
export const CAMPUSES = 'campuses';
export const REGIONS = 'regions';
export const PROMOCODES = 'promocodes';
export const SPACES = 'spaces';
export const LOGOUT = 'logout';
export const GlobalCategories = 'globalcategories';

export const SIDEBAR_ARRAY = [
  SERVICES,
  PARTNERS,
  REPORTS,
  USERS,
  COMPANIES,
  ADMINS,
  CAMPUSES,
  REGIONS,
  LOGOUT,
  PROMOCODES,
  SPACES,
];

export const SIDEBAR_ICONS = {
  [SERVICES]: SERVICES_ICON,
  [PARTNERS]: PARTNERS_ICON,
  [REPORTS]: REPORTS_ICON,
  [USERS]: USERS_ICON,
  [COMPANIES]: COMPANIES_ICON,
  [ADMINS]: ADMINS_ICON,
  [CAMPUSES]: CAMPUSES_ICON,
  [REGIONS]: REGIONS_ICON,
  [LOGOUT]: LOGOUT_ICON,
  [PROMOCODES]: PromoCodeIcon,
  [SPACES]: SpacesIcon,
  [GlobalCategories]: REGIONS_ICON,
};
