/* eslint-disable multiline-ternary */
/**
 * Data Layer Composition Root.
 *
 * This is where all services, sagas, and reducers should be declared,
 * initialized and combined to build the Redux Store.
 */

/** Absolute Imports **/
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware, LOCATION_CHANGE } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form/immutable';
import {
  bindHttp,
  MeServiceRaw,
  LocationService,
  AuthService,
  AdminUserServiceRaw,
  BuildingServiceRaw,
  RegionAdminServiceRaw,
  CampusAdminServiceRaw,
  CompanyAdminServiceRaw,
  EnrollmentAdminServiceRaw,
  PartnerServiceRaw,
  AdminReportsServiceRaw,
  PromoCodeServiceRaw,
  SpacesService,
  AdminGlobalCategoriesServiceRaw,
  CloudinaryAdminServiceRaw,
} from 'zo-data-layer/services';
import {
  forkAll,
  MeSagas,
  LocationSagas,
  AuthSagas,
  BuildingSagas,
  LayoutSagasWeb,
  AdminUsersSagas,
  AdminManageRegionsSagas,
  AdminManageCampusesSagas,
  AdminManageCompaniesSagas,
  AdminManageEnrollmentsSagas,
  AdminManageAdminUsersSagas,
  AdminManagePartnersSagas,
  AdminReportsSagas,
  AdminManagePromoCodeSagas,
  AdminManageSpacesSagas,
  AdminManageGlobalCategoriesSagas,
  AdminCloudinarySagas,
} from 'zo-data-layer/sagas';
import {
  me,
  location,
  auth,
  users,
  regions,
  campuses,
  companies,
  partners,
  adminUsers,
  promocodes,
  enrollments,
  layoutReducerWeb,
  buildingsAndFloors,
  languageProviderReducer,
  adminSpaces as spaces,
  adminGlobalCategories as globalCategories,
  cloudinary,
} from 'zo-data-layer/reducers';

/** Relative Imports **/
import { setErrorReporter } from 'zo-data-layer/transformers';
import ErrorReporter from './analytics/error.analytics';
import { Token } from './auth';
import {
  redirectToLogin,
  redirectToHome,
  redirectToServices,
  reloadLocation,
} from './utils/navigation';

/**
 * Http library
 */
import http from './generic.service';
import { LogoutActionTypes } from 'zo-data-layer/constants/actions';
import { bindClassBasedServiceToCallableAdminService } from 'zo-data-layer/sagas/sagas.helpers';
import SideEffectsSagas from './sagas/SideEffects.sagas';

function logout() {
  Token.destroy();
  redirectToLogin();
  return Promise.resolve(undefined);
}

setErrorReporter(ErrorReporter);
/**
 * Initialize all the saga collection by injecting dependencies into new instances
 * Raw services should be bound to the generic http library before injection
 */
const authSagas = new AuthSagas({
  service: new AuthService(http),
  saveToken: (token) => Promise.resolve(Token.set(token)),
  getToken: () => Promise.resolve(Token.get()),
  getBackupToken: () => Promise.resolve(Token.getBackupToken()),
  toHome: () => Promise.resolve(redirectToHome()),
  toLogin: () => Promise.resolve(redirectToLogin()),
  logout,
});
const meSagas = new MeSagas({
  service: bindHttp(MeServiceRaw, http),
});
const locationSagas = new LocationSagas(new LocationService(http));
const layoutSagas = new LayoutSagasWeb({
  service: bindHttp(MeServiceRaw, http),
  error: ErrorReporter,
});
const buildingSagas = new BuildingSagas({
  service: bindHttp(BuildingServiceRaw, http),
});
const adminManageEnrollmentsSagas = new AdminManageEnrollmentsSagas({
  service: bindHttp(EnrollmentAdminServiceRaw, http),
  toServices: redirectToServices,
});
const adminUsersSagas = new AdminUsersSagas({
  service: bindHttp(AdminUserServiceRaw, http, Token),
});
const adminManageRegionsSagas = new AdminManageRegionsSagas({
  service: bindHttp(RegionAdminServiceRaw, http),
});
const adminManageCampusesSagas = new AdminManageCampusesSagas({
  service: bindHttp(CampusAdminServiceRaw, http),
});
const adminManageCompaniesSagas = new AdminManageCompaniesSagas({
  service: bindHttp(CompanyAdminServiceRaw, http),
});
const adminManageAdminUsersSagas = new AdminManageAdminUsersSagas({
  saveToken: (token) => {
    const currentToken = Token.get();
    Token.setBackup(currentToken);
    Token.set(token);
  },
  resetToken: () => {
    const backupToken = Token.getBackupToken();
    Token.set(backupToken);
    Token.destroyBackup();
  },
  service: bindHttp(AdminUserServiceRaw, http),
  reload: reloadLocation,
});
const adminManagePartnersSagas = new AdminManagePartnersSagas({
  service: bindHttp(PartnerServiceRaw, http),
});
const adminReportsSagas = new AdminReportsSagas({
  service: bindHttp(AdminReportsServiceRaw, http),
});
const adminManagePromoCodeSagas = new AdminManagePromoCodeSagas({
  service: bindHttp(PromoCodeServiceRaw, http),
});
//see comment in the data-layer about this function
//SpacesService was modernized but the admin saga was not
//this helps to ease that transition
const adminManageSpacesSagasService = bindClassBasedServiceToCallableAdminService(
  new SpacesService(http)
);
const adminManageSpacesSagas = new AdminManageSpacesSagas({
  service: adminManageSpacesSagasService,
});

const adminManageGlobalCategoriesSagas = new AdminManageGlobalCategoriesSagas({
  service: bindHttp(AdminGlobalCategoriesServiceRaw, http),
});
const cloudinarySagas = new AdminCloudinarySagas({
  service: bindHttp(CloudinaryAdminServiceRaw, http),
});
const sideEffectsSagas = new SideEffectsSagas();

/**
 * Declare the sagas to be mounted
 *
 * Each saga collection should be declared in one array element
 *  the first element is the saga collection instance
 *  the second element is an array naming all the specific sagas to be mounted
 */
const sagas = [
  [authSagas, ['authWeb', 'registration']],
  [meSagas, ['getMe']],
  [locationSagas, ['getLocation']],
  [layoutSagas, ['onRouteChange']],
  [buildingSagas, ['getBuildingsAndFloors']],
  [adminManageEnrollmentsSagas, ['getEnrollments', 'setEnrollmentForView', 'enrollment']],
  [adminUsersSagas, ['getUsers', 'getUser', 'userForm', 'bulkUpload', 'bulkEmail']],
  [adminManageRegionsSagas, ['getRegions', 'regionForm']],
  [adminManageCampusesSagas, ['getCampuses', 'campusForm', 'getGlobalCampuses', 'connectStripe']],
  [
    adminManageCompaniesSagas,
    [
      'getCompanies',
      'companyForm',
      'getCompanyById',
      'getEnrollmentsByCompany',
      'getEmailDomainsByCompany',
      'addEmailDomainsByCompany',
      'unEnrollCompanyFromService',
      'deleteEmailDomainFromCompany',
    ],
  ],
  [adminManageAdminUsersSagas, ['getAdminUsers', 'adminUserForm']],
  [adminManagePartnersSagas, ['getPartners', 'partnerForm']],
  [
    adminReportsSagas,
    [
      'requestUsersReportByEmail',
      'requestBookingsReportByEmail',
      'requestScheduledServiceBookingReportByEmail',
    ],
  ],
  [adminManagePromoCodeSagas, ['admin']],
  [adminManageSpacesSagas, ['admin']],
  [adminManageGlobalCategoriesSagas, ['admin', 'reorderCategories']],
  [cloudinarySagas, ['createCloudinarySignature']],
  [sideEffectsSagas, ['sideEffects']],
];

/**
 * Root saga
 */
const webSagas = function* webSagas() {
  yield forkAll(sagas);
};
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

/**
 * Root reducer
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  if (action.type === LOCATION_CHANGE) {
    return state.merge({
      locationBeforeTransitions: action.payload,
    });
  } else {
    return state;
  }
}

const rootReducer = combineReducers({
  route: routeReducer,
  app: combineReducers({
    me,
    location,
    auth,
    buildingsAndFloors,
  }),
  admin: combineReducers({
    users,
    regions,
    partners,
    campuses,
    companies,
    adminUsers,
    enrollments,
    promocodes,
    spaces,
    globalCategories,
    cloudinary,
  }),
  form: formReducer,
  layout: layoutReducerWeb,
  language: languageProviderReducer,
});

/**
 * Wraps the root reducer to automatically reset state when the LOGOUT action is caught
 */
const wrapperReducer = (state, action) => {
  if ([LogoutActionTypes.success, LogoutActionTypes.failure].includes(action.type)) {
    state = undefined;
  }
  return rootReducer(state, action);
};

/**
 * Build a function that will create the Redux store
 */
export default function configureStore(initialState = {}, history) {
  // declare Redux middleware
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  // apply middleware
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.APP_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  // initialize store
  const store = createStore(wrapperReducer, fromJS(initialState), composeEnhancers(...enhancers));

  // attach sagas
  sagaMiddleware.run(webSagas);

  // Redux store to be injected into the <Provider />
  return store;
}
