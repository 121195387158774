import React from 'react';
import PropTypes from 'prop-types';

const AdminsIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M15.246 16.185l-2.956-1.782-2.956 1.782.783-3.36-2.607-2.259 3.438-.294L12.29 7.1l1.342 3.166 3.438.293-2.607 2.257.783 3.37zm4.14-11.364c-2.33-.092-4.568-.98-6.285-2.513a1.263 1.263 0 0 0-1.654 0C9.7 3.871 7.463 4.76 5.195 4.821 4.521 4.852 4 5.372 4 6.047v9.225c0 .184.031.337.092.49a10.572 10.572 0 0 0 6.834 5.885c1.597.434 1.112.474 2.727-.03 3.066-.828 5.548-2.974 6.835-5.886.061-.153.092-.337.092-.49V6.047c0-.675-.522-1.195-1.195-1.226z"
      />
    </g>
  </svg>
);

AdminsIcon.propTypes = {
  color: PropTypes.string,
};

export default AdminsIcon;
