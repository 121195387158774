import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/LocalizationUtils';
import { compose } from 'redux';

import { MeSelectors } from 'zo-data-layer/selectors';
import { isProduction } from 'zo-data-layer/constants/env';

const zenDeskKey = isProduction()
  ? '5902e62c-016e-4a97-82d8-dc68b100673a'
  : '5b60db74-95f8-4f76-ad0d-b66ffa967099';

class ZenDeskWidget extends Component {
  static propTypes = {
    zendeskWidgetJwtToken: PropTypes.string,
    intl: intlShape.isRequired,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  };

  state = {
    isScriptLoading: false,
    isScriptLoaded: false,
  };

  componentDidMount() {
    if (this.props.zendeskWidgetJwtToken) {
      this.setState({ isScriptLoading: true }, this.injectScript);
    }
  }

  componentDidUpdate(prevProps) {
    const { zendeskWidgetJwtToken } = this.props;
    const { isScriptLoading, isScriptLoaded } = this.state;

    // if script is not injected yet.
    if (zendeskWidgetJwtToken && !isScriptLoading && !isScriptLoaded) {
      this.injectScript();
      return;
    }

    if (isScriptLoaded && prevProps.zendeskWidgetJwtToken !== zendeskWidgetJwtToken) {
      if (!zendeskWidgetJwtToken) {
        window.zE.logout();
        window.zE.setHelpCenterSuggestions({ url: true }); // clear search suggestions
        window.zE('webWidget', 'close'); // close widget
        window.zE('webWidget', 'clear'); // clear search form
        window.zE('webWidget', 'hide');
      }
    }
  }

  onScriptLoaded = () => {
    this.setState({ isScriptLoading: false, isScriptLoaded: true });
    this.setupWebWidget();
  };

  setupWebWidget() {
    window.zE('webWidget', 'identify', {
      name: this.props.fullName,
      email: this.props.email,
    });
    window.zE('webWidget', 'setLocale', this.props.intl.locale);
    window.zE('webWidget', 'prefill', {
      name: {
        value: this.props.fullName,
      },
      email: {
        value: this.props.email,
      },
      phone: {
        value: this.props.phone,
      },
    });
    window.zE('webWidget', 'show');
  }

  injectScript = () => {
    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: (callback) => {
            if (this.props.zendeskWidgetJwtToken) {
              callback(this.props.zendeskWidgetJwtToken);
            }
          },
        },
      },
    };

    const script = document.createElement('script');
    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zenDeskKey}`;
    script.addEventListener('load', this.onScriptLoaded);
    document.body.appendChild(script);
  };

  render() {
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  zendeskWidgetJwtToken: MeSelectors.zendeskWidgetJwtToken(),
  email: MeSelectors.myEmail(),
  fullName: MeSelectors.myFullName(),
  phone: MeSelectors.myPhone(),
});

export default compose(connect(mapStateToProps), injectIntl)(ZenDeskWidget);
