import React from 'react';

const SpacesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 1664 1632"
    style={{
      msTransform: 'rotate(360deg)',
      WebKitTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)',
    }}
  >
    <path
      d="M1440 320q0-40-28-68t-68-28-68 28-28 68 28 68 68 28 68-28 28-68zm224-288q0 249-75.5 430.5T1335 823q-81 80-195 176l-20 379q-2 16-16 26l-384 224q-7 4-16 4-12 0-23-9l-64-64q-13-14-8-32l85-276-281-281-276 85q-3 1-9 1-14 0-23-9l-64-64q-17-19-5-39l224-384q10-14 26-16l379-20q96-114 176-195 188-187 358-258t431-71q14 0 24 9.5t10 22.5z"
      fill="#626262"
    />
    <rect x="0" y="0" width="1664" height="1632" fill="rgba(0, 0, 0, 0)" />
  </svg>
);

export default SpacesIcon;
