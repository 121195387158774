import { browserHistory } from 'react-router';

export function redirectToLogin() {
  browserHistory.push({
    pathname: '/login',
  });
}

export function redirectToHome() {
  browserHistory.push({
    pathname: '/',
  });
}

export function redirectToServices() {
  browserHistory.push({
    pathname: '/services',
  });
}

export function reloadLocation() {
  window.location.reload();
}
