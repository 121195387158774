import React from 'react';

const PromoCodeIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 11 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Editor" transform="translate(-102.000000, -51.000000)" fill="#ADB0B7">
        <g id="ic_attach_money" transform="translate(96.000000, 48.000000)">
          <g id="Icon-24px">
            <path
              d="M11.8,10.9 C9.53,10.31 8.8,9.7 8.8,8.75 C8.8,7.66 9.81,6.9 11.5,6.9 C13.28,6.9 13.94,7.75 14,9 L16.21,9 C16.14,7.28 15.09,5.7 13,5.19 L13,3 L10,3 L10,5.16 C8.06,5.58 6.5,6.84 6.5,8.77 C6.5,11.08 8.41,12.23 11.2,12.9 C13.7,13.5 14.2,14.38 14.2,15.31 C14.2,16 13.71,17.1 11.5,17.1 C9.44,17.1 8.63,16.18 8.52,15 L6.32,15 C6.44,17.19 8.08,18.42 10,18.83 L10,21 L13,21 L13,18.85 C14.95,18.48 16.5,17.35 16.5,15.3 C16.5,12.46 14.07,11.49 11.8,10.9 L11.8,10.9 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PromoCodeIcon;
