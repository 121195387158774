import { takeEvery, put } from 'redux-saga/effects';

import { FetchMeActionTypes } from 'zo-data-layer/constants/actions';
import { UserRole } from 'zo-data-layer/constants/permissions';
import { getAppRoute } from 'zo-data-layer/constants/api';
import { setGlobalCampus } from 'zo-data-layer/actions';

import { Token } from '../auth';

export default class SideEffectsSagas {
  private *_sideEffects() {
    yield takeEvery(FetchMeActionTypes.success, this.checkUserRole.bind(this));
  }
  public sideEffects = this._sideEffects.bind(this);

  private *checkUserRole({ payload }) {
    const role: UserRole = payload.role;
    if (role === UserRole.Enduser) {
      Token.destroy();
      window.location.replace(getAppRoute());
      return;
    }
    yield put(setGlobalCampus(payload.company.campus));
  }
}
