import ReactLoadable from 'react-loadable';
import { LoadingBar } from '../LoadingBar';

export default function Loadable(opts) {
  return ReactLoadable(
    Object.assign(
      {
        loading: LoadingBar,
        delay: 50,
      },
      opts
    )
  );
}
