/*
 * Generic Service
 *
 */

/** Absolute Imports **/
import 'whatwg-fetch';

/** Data Layer **/
import {
  throwNetworkTimeoutError,
  throwTypedErrorFromJson,
} from 'zo-data-layer/transformers/error.transformers';

/** Relative Imports **/
import { Token } from './auth';
import { redirectToLogin } from './utils/navigation';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.text().then((text: string) => (text ? JSON.parse(text) : {}));
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    Token.destroy();
    redirectToLogin();
  }

  return parseError(response);
}

const parseError = (response) =>
  parseJSON(response).then((jsonData) => throwTypedErrorFromJson(jsonData, response.url));

export const URIEncode = (params) =>
  Object.keys(params)
    .filter((k) => params[k] !== undefined && params[k] !== null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

/**
 * Catch unexpected fetch Errors (usually a result of the API being down)
 *
 * "expected" errors (thrown by parseError() above) are passed through
 * to be handled by the sagas.
 *
 * @param  {Error}
 * @return {Error}
 */
const unexpectedFetchErrors = (error) => {
  if (error.message === 'Failed to fetch') {
    throwNetworkTimeoutError();
  }

  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string}
 * @param  {object}
 *
 * @return {promise}
 */
export default function request(url, options) {
  const token = Token.get();
  const defaultOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `${token.token_type} ${token.access_token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, Object.assign(defaultOptions, options))
    .then(checkStatus)
    .then(parseJSON)
    .catch(unexpectedFetchErrors);
}
