/**
 * routes.js
 *
 * This is the routes file for the application. All route components are loaded async
 * to allow chunking and reduce the initial load size.
 */

// Import all the third party stuff
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route, Redirect, browserHistory } from 'react-router';
import { CloudinaryContext } from 'cloudinary-react';
import { makeSelectLocationState } from 'zo-data-layer/selectors';

import { syncHistoryWithStore } from 'react-router-redux';
import 'sanitize.css/sanitize.css';

/** Internal Dependencies **/

// Import Analytics Reporter for page views
import { CloudName } from 'zo-data-layer/constants/cloudinary';
import Analytics from './analytics/ga.analytics';

// Import selector for `syncHistoryWithStore`

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider';

// Import Composition Root
import configureStore from './compositionRoot';

// Import root component
import App from './containers/App';

// Import authentication check
import { Allow, logoutTo } from './auth';

// Import Components
import NotFound from './pages/NotFound';

// Import Async Loadable
import Loadable from './component-library/containers/Loadable';
import ZenDeskWidget from './containers/ZenDeskWidget';

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

const trackPageView = Analytics.logPageView.bind(Analytics);

const sanitizeParams = (queries, path, next, replace) => {
  const allowedQueries = queries.filter((q) => q in next.location.query).length > 0;
  if (!allowedQueries) {
    replace(path);
  }
};

const sanitizeParamsForgotConfirm = sanitizeParams.bind(null, ['token'], '/login');

// Set up the router, wrapping all Routes in the App component
const Routes = ({ messages }) => (
  <Provider store={store}>
    <LanguageProvider messages={messages}>
      <CloudinaryContext cloudName={CloudName}>
        <ZenDeskWidget />
        <Router history={history} onUpdate={trackPageView}>
          <Route
            path="login"
            component={Loadable({ loader: () => import('./pages/Login') })}
            onEnter={Allow.unauthenticated.orRedirectTo('/')}
          />
          <Route
            path="auth/receive-token"
            component={Loadable({ loader: () => import('./pages/ReceiveToken') })}
          />
          <Route
            path="auth/confirm"
            onEnter={sanitizeParamsForgotConfirm}
            component={Loadable({ loader: () => import('./pages/ConfirmAccount') })}
          />
          <Redirect from="/" to="users" />
          <Route path="/" component={App} onEnter={Allow.authenticated.orRedirectTo('/login')}>
            <Route path="logout" onEnter={logoutTo('/login')} />
            <Route
              path="services"
              component={Loadable({ loader: () => import('./pages/Services') })}
            />
            <Route
              path="services/new"
              component={Loadable({ loader: () => import('./pages/Service') })}
            />
            <Route
              path="services/:id"
              component={Loadable({ loader: () => import('./pages/Service') })}
            />
            <Route
              path="partners"
              component={Loadable({ loader: () => import('./pages/Partners') })}
            />
            <Route path="users" component={Loadable({ loader: () => import('./pages/Users') })} />
            <Route path="admins" component={Loadable({ loader: () => import('./pages/Admins') })} />
            <Route
              path="companies"
              component={Loadable({ loader: () => import('./pages/Companies') })}
            />
            <Route
              path="companies/:id"
              component={Loadable({ loader: () => import('./pages/Company') })}
            />
            <Route
              path="campuses"
              component={Loadable({ loader: () => import('./pages/Campuses') })}
            />
            <Route
              path="regions"
              component={Loadable({ loader: () => import('./pages/Regions') })}
            />
            <Route
              path="promocodes"
              component={Loadable({ loader: () => import('./pages/PromoCodes') })}
            />
            <Route path="spaces" component={Loadable({ loader: () => import('./pages/Spaces') })} />
            <Route
              path="reports"
              component={Loadable({ loader: () => import('./pages/Reports') })}
            />
            <Route
              path="stripe_connect_campus"
              component={Loadable({ loader: () => import('./pages/StripeConnectCampus') })}
            />
            <Route
              path="globalcategories"
              component={Loadable({ loader: () => import('./pages/GlobalCategories') })}
            />
          </Route>
          <Route path="*" component={NotFound} />
        </Router>
      </CloudinaryContext>
    </LanguageProvider>
  </Provider>
);

Routes.propTypes = {
  messages: PropTypes.object.isRequired,
};

export default Routes;
