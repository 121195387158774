/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import navigatorLanguages from 'navigator-languages';
import { Locale } from '../../constants';
import { ErrorReporter } from '../../analytics';

import './moment-de';
import './moment-fr';

// sometimes languages have a region code (e.g. en_GB), turn that into just 'en'
const normalizeLanguage = (language) => language.toLowerCase().split(/[_-]+/)[0];

const userPreferredLanguages = navigatorLanguages().map(normalizeLanguage);

const chooseLanguage = (appMessages) => {
  // languages in order of user preference, single language for backwards compat
  const locale = userPreferredLanguages.find((normalizedLocale) =>
    appMessages.hasOwnProperty(normalizedLocale)
  );
  return locale || Locale.DEFAULT_LOCALE;
};

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const locale = chooseLanguage(this.props.messages);
    ErrorReporter.addContext({ locale });
    // set global locale for moment
    moment.locale(locale);
    const messages = this.props.messages[locale] || this.props.messages.en;
    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
