import React from 'react';
import PropTypes from 'prop-types';

const CompaniesIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M14 7h-4V5h4v2zm6 0h-4V5l-2-2h-4L8 5v2H4c-1.11 0-2 .89-2 2v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
      />
    </g>
  </svg>
);

CompaniesIcon.propTypes = {
  color: PropTypes.string,
};

export default CompaniesIcon;
