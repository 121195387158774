import React from 'react';
import PropTypes from 'prop-types';

const ServicesIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M2 17h20v2H2v-2zm11.84-9.21A2.006 2.006 0 0 0 12 5a2.006 2.006 0 0 0-1.84 2.79C6.25 8.6 3.27 11.93 3 16h18c-.27-4.07-3.25-7.4-7.16-8.21z"
      />
    </g>
  </svg>
);

ServicesIcon.propTypes = {
  color: PropTypes.string,
};

export default ServicesIcon;
