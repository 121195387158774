/**
 * HOC to remove the static loader once the app has mounted
 */

/** External Dependencies **/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** Internal Dependencies **/

const withRemoveLoader = (WrappedComponent) =>
  class RemoveLoader extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
    };

    componentDidMount() {
      const loader = document.getElementById('loader');
      loader.classList.add('fadeOut');
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withRemoveLoader;
