/**
 *
 * Drawer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

/* Styling */
import styles from './style.module.scss';

/* Components */
import X from '../../component-library/generic/SVGs/X';

const Drawer = ({ children, open, close }) => (
  <div className={`${styles.drawer} ${open ? styles.open : styles.close}`}>
    <button className={styles.button} onClick={close}>
      <X className={styles.icon} />
    </button>
    {children}
  </div>
);

Drawer.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.node,
};

export default Drawer;
