import React from 'react';

const LogoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#AEB0B8">
        <path d="M15.737 8.41L18.316 11h-9.67v2h9.67l-2.58 2.59 1.41 1.41 5-5-5-5z" />
        <path d="M2 5v14c0 1.1.9 2 2 2h9a2 2 0 0 0 2-2v-4h-2v4H4V5h9v4h2V5a2 2 0 0 0-2-2H4c-1.1 0-2 .9-2 2" />
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
