import { defineMessages } from 'react-intl';

export default defineMessages({
  servicesLink: {
    id: 'zo.app.components.SideBar.servicesLink',
    defaultMessage: 'Manage Services',
  },
  partnersLink: {
    id: 'zo.app.components.SideBar.partnersLink',
    defaultMessage: 'Manage Partners',
  },
  usersLink: {
    id: 'zo.app.components.SideBar.usersLink',
    defaultMessage: 'Manage Users',
  },
  companiesLink: {
    id: 'zo.app.components.SideBar.companiesLink',
    defaultMessage: 'Manage Companies',
  },
  reportsLink: {
    id: 'zo.app.components.SideBar.reportsLink',
    defaultMessage: 'Reports',
  },
  spacesLink: {
    id: 'zo.app.components.SideBar.spacesLink',
    defaultMessage: 'Manage Spaces',
  },
  manageAdminsLink: {
    id: 'zo.app.components.SideBar.manageAdminsLink',
    defaultMessage: 'Manage Admins',
  },
  manageCampusesLink: {
    id: 'zo.app.components.SideBar.manageCampusesLink',
    defaultMessage: 'Manage Campuses',
  },
  manageRegionsLink: {
    id: 'zo.app.components.SideBar.manageRegionsLink',
    defaultMessage: 'Manage Regions',
  },
  managePromoCodesLink: {
    id: 'zo.app.components.SideBar.managePromoCodesLink',
    defaultMessage: 'Manage Promo Codes',
  },
  manageHeadersLink: {
    id: 'zo.app.components.SideBar.manageHeadersLink',
    defaultMessage: 'Manage Headers',
  },
  manageGlobalCategoriesLink: {
    id: 'zo.app.components.SideBar.manageGlobalCategoriesLink',
    defaultMessage: 'Global Categories',
  },
  logOut: {
    id: 'zo.app.components.SideBar.logOut',
    defaultMessage: 'Log Out',
  },
  global_admin: {
    id: 'zo.app.roles.globalRole',
    defaultMessage: 'Global Admin',
  },
  campus_admin: {
    id: 'zo.app.roles.campusRole',
    defaultMessage: 'Campus Admin',
  },
  leader: {
    id: 'zo.app.roles.leaderRole',
    defaultMessage: 'Leader',
  },
  enduser: {
    id: 'zo.app.roles.enduserRole',
    defaultMessage: 'End User',
  },
  globalAdminTools: {
    id: 'zo.app.components.SideBar.globalAdminTools',
    defaultMessage: 'Global Admin Tools',
  },
  cancelImpersonate: {
    id: 'zo.app.components.SideBar.cancelImpersonate',
    defaultMessage: 'Stop impersonation',
  },
});
