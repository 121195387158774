import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/** Styling **/
import styles from './style.module.scss';

/* Internal Dependencies */
import ProgressBar from './Bar';
import Messages from './Messages';

export const LoadingBar = ({ isLoading, error }) => {
  if (isLoading) {
    // While our other component is loading...
    return <ProgressBar />;
  } else if (error) {
    // If we aren't loading, maybe
    console.error('Dynamic page loading failed!', error);
    return <ZoSorry />;
  }
  return null;
};

LoadingBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

const ZoSorry = () => (
  <div className={styles.errorWrapper}>
    <div className={styles.errorCard}>
      <h3>
        <FormattedMessage {...Messages.headingText} />
      </h3>
      <p>
        <FormattedMessage {...Messages.bodyText} />
      </p>
    </div>
  </div>
);
