import * as Sentry from '@sentry/browser';
import { isProduction, env } from 'zo-data-layer/constants/env';
import { version } from '../../package.json';

import { SENTRY_DSN } from '../constants/analytics';

Sentry.init({
  dsn: SENTRY_DSN,
  debug: !isProduction(),
  maxBreadcrumbs: 50,
  environment: env(),
  release: version,
  attachStacktrace: true,
});

class ErrorReporter {
  constructor() {
    switch (process.env.APP_ENV) {
      case 'production':
        this.reporter = RavenErrorReporter;
        break;
      case 'staging':
        this.reporter = RavenErrorReporter;
        break;
      default:
        this.reporter = DevErrorReporter;
        break;
    }
  }

  captureMessage(msg, context) {
    this.reporter.captureMessage(msg, context);
  }

  captureException(e, context) {
    this.reporter.captureException(e, context);
  }

  setUserContext(context) {
    this.reporter.setUserContext(context);
  }

  addContext(context) {
    this.reporter.addContext(context);
  }
}

class RavenErrorReporter {
  static captureMessage(msg, context) {
    Sentry.captureMessage(msg, context);
  }

  static captureException(e, context) {
    Sentry.captureException(e, context);
  }

  static setUserContext(context) {
    Sentry.setUser(context);
  }

  static addContext(context) {
    Sentry.setExtra(context);
  }
}

class DevErrorReporter {
  static captureMessage(msg, context) {
    console.log('CaptureMessage: ', msg, context);
  }

  static captureException(e, context) {
    console.log('CaptureException: ', e, context);
  }

  static setUserContext(context) {
    console.log('SetUserContext: ', context);
  }

  static addContext(context) {
    console.log('ExtraContext: ', context);
  }
}

export default new ErrorReporter();
