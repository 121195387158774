import AccessToken from './utils/authentication/AccessToken';

/**
 * Apps AccessToken.
 *
 * @type {AccessToken}
 */
const Token = new AccessToken();

/**
 * A blank redirect function that will continue through routing.
 *
 * Used as a helper function.
 *
 * @param nextState
 * @param replace
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
function next() {}

/**
 * A generic redirect action used by onEnter hooks.
 */
class RedirectAction {
  public replacePath: string;
  public redirectFunction: (nextState, replace) => void;
  /**
   * Constructor.
   *
   * @param {String} replacePath - the path used by the replace function.
   * @param {Function} redirectFunction - A callback function used by React Router for onEnter/onLeave hooks.
   */
  constructor({ replacePath = '/', redirectFunction = next }) {
    this.replacePath = replacePath;
    this.redirectFunction = redirectFunction;
  }

  /**
   * Sets the replace path for this Redirect Action.
   *
   * @param {String} path
   *
   * @returns {RedirectAction.redirectFunction}
   */
  orRedirectTo(path) {
    this.replacePath = path || this.replacePath;

    return this.redirectFunction;
  }
}

/**
 * A redirect function that will redirect to the given path.
 *
 * @param path
 *
 * @returns {Function}
 */
function redirectTo(path) {
  return (nextState, replace) => {
    replace(path);
  };
}

/**
 * Performs the logout on the access token.
 *
 * @param path
 *
 * @returns {Function}
 */
function logoutTo(path = '/login') {
  return (nextPath, replace) => {
    Token.destroy();

    replace(path);
  };
}

/**
 * Authorization factory.
 *
 * Allow will create RedirectActions and set the proper logic as the redirect
 * function which will be evaluated by the onEnter hook.
 *
 * Example:
 *      <Route path="/me" component={App} onEnter={Allow.authenticated.orRedirectTo('/login')}>
 *
 * @type {{authenticated, unauthenticated, role: Allow.role}}
 */
const Allow = {
  /**
   * Verify a user is authenticated.
   *
   * @returns {RedirectAction}
   */
  get authenticated() {
    const redirectFunction = new RedirectAction({
      replacePath: '/login', // A sensible default.
    });

    redirectFunction.redirectFunction = (nextState, replace) => {
      if (!Token.isAuthenticated()) {
        replace(`${redirectFunction.replacePath}${nextState.location.search}`);
      }
    };

    return redirectFunction;
  },

  /**
   * Verify user is unauthenticated. Blocks access to routes that
   * authenticated users should not be allowed visibility.
   *
   * Useful for routes like '/login'
   *
   * @returns {RedirectAction}
   */
  get unauthenticated() {
    const redirectFunction = new RedirectAction({
      replacePath: '/', // A sensible default.
    });

    redirectFunction.redirectFunction = (nextState, replace) => {
      if (Token.isAuthenticated()) {
        replace(`${redirectFunction.replacePath}${nextState.location.search}`);
      }
    };

    return redirectFunction;
  },

  /**
   * Verify user has proper role.
   *
   * @param {String} role
   *
   * @returns {*}
   */
  role: (role) => {
    const redirectFunction = new RedirectAction({
      replacePath: '/denied', // A sensible default.
    });

    redirectFunction.redirectFunction = (nextPath, replace) => {
      if (!Token.hasScope(role)) {
        replace(redirectFunction.replacePath);
      }
    };

    return redirectFunction;
  },
};

export { Allow, Token, redirectTo, logoutTo, RedirectAction };
