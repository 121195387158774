import PropTypes from 'prop-types';
import defaultPhrases from 'react-dates/lib/defaultPhrases';
import { SingleDatePickerPhrases } from 'react-dates';
import { IntlShape, MessageDescriptor } from 'react-intl';

type LabelType = {
  labelId: string;
  defaultLabel?: string;
  labelDescription?: string;
};

type Labels<T> = {
  [key in keyof T]: LabelType;
};

/*
  Defines formats appropriate to easily be passed to input fields
  which accept labelID, defaultLabel, and labelDescription
 */
export function defineLabels<T extends Record<string, MessageDescriptor>>(labelInfo: T): Labels<T> {
  const labelMessages = {};

  Object.keys(labelInfo).forEach((identifier) => {
    labelMessages[identifier] = {
      labelId: labelInfo[identifier].id,
      defaultLabel: labelInfo[identifier].defaultMessage,
      labelDescription: labelInfo[identifier].description,
    };
  });

  return labelMessages as Labels<T>;
}

export const intlShape = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
  locale: PropTypes.string,
});

interface Message {
  id: string;
  defaultMessage?: string;
}

export interface intlShapeTS {
  formatMessage: (message: Message) => void;
  locale?: string;
}

const reactDatesPhraseNames = [
  'calendarLabel',
  'closeDatePicker',
  'focusStartDate',
  'clearDate',
  'clearDates',
  'jumpToPrevMonth',
  'jumpToNextMonth',
  'keyboardShortcuts',
  'showKeyboardShortcutsPanel',
  'hideKeyboardShortcutsPanel',
  'openThisPanel',
  'enterKey',
  'leftArrowRightArrow',
  'upArrowDownArrow',
  'pageUpPageDown',
  'homeEnd',
  'escape',
  'questionMark',
  'selectFocusedDate',
  'moveFocusByOneDay',
  'moveFocusByOneWeek',
  'moveFocusByOneMonth',
  'moveFocustoStartAndEndOfWeek',
  'returnFocusToInput',
  'keyboardNavigationInstructions',
];

const reactDatesFunctionPhrases = [
  'chooseAvailableStartDate',
  'chooseAvailableEndDate',
  'dateIsUnavailable',
  'dateIsSelected',
];

export function translateReactDatePhrases(intl: IntlShape): SingleDatePickerPhrases {
  const phrases = {};
  reactDatesPhraseNames.forEach((name) => {
    phrases[name] = intl.formatMessage({
      id: `zo.app.ReactDatePhrases.${name}`,
      defaultMessage: defaultPhrases[name],
    });
  });
  reactDatesFunctionPhrases.forEach((name) => {
    phrases[name] = (values) =>
      intl.formatMessage(
        {
          id: `zo.app.ReactDatePhrases.${name}`,
          defaultMessage: defaultPhrases[name](values),
        },
        values
      );
  });
  return phrases;
}
