/** Absolute Imports **/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

/** Relative Imports **/
import { dismissToast } from 'zo-data-layer/actions';
import { makeSelectToastState } from 'zo-data-layer/selectors';
import { Toast as ToastConstants } from '../../constants';

/** Styling **/
import styles from './style.module.scss';

/** Component Imports **/
import Cross from '../../component-library/generic/SVGs/Cross';

const timedDismiss = (display, dismiss) => {
  if (display) {
    setTimeout(dismiss, 4000);
  }
};

const className = (classes, display, type) =>
  [classes.message, classes[type]].concat(display ? [classes.animate] : [classes.hidden]).join(' ');

const crossColor = (type) => {
  switch (type) {
    case ToastConstants.Types.error:
    case ToastConstants.Types.info:
      return 'white';
    case ToastConstants.Types.infoGray:
      return '#20556c';
    default:
      return 'white';
  }
};

const Toast = ({ toast, dismiss }) => (
  <div
    data-testid="toast-message-container"
    id={styles.toast}
    className={className(styles, toast.get('display'), toast.get('type'))}
  >
    {timedDismiss(toast.get('display'), dismiss)}
    <span>
      {toast.get('message').id ? (
        <FormattedMessage {...toast.get('message')} />
      ) : (
        toast.get('message')
      )}
    </span>
    <div className={styles.forceDismiss} onClick={dismiss}>
      <Cross color={crossColor(toast.get('type'))} />
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  toast: makeSelectToastState(),
});

const mapDispatchToProps = (dispatch) => ({
  dismiss: () => dispatch(dismissToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);

Toast.propTypes = {
  toast: PropTypes.object,
  dismiss: PropTypes.func,
};
