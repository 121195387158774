/** External Dependencies **/
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies **/
import { makeSelectIsGlobalAdmin, makeSelectMyData, MeSelectors } from 'zo-data-layer/selectors';

import Messages from './Messages';

/** Components **/
import Drawer from './Drawer';

/** Styling **/
import styles from './style.module.scss';

/* Assets */
import AccountIcon from '../../images/icons/ic-account';
import SideBarLink from '../SideBarLink';

const SideBar = ({
  me,
  close,
  open,
  impersonateAlertOpen,
  cancelImpersonate,
  logout,
  isGlobalAdmin,
  isLeader,
}) => (
  <Drawer open={open} close={close} className={styles.drawer}>
    <div
      className={`${styles.userDetails} ${impersonateAlertOpen ? styles.impersonate : ''}`}
      id="userProfileCard"
    >
      <AccountIcon />
      <p className={styles.userName}>
        {me.get('first_name')} {me.get('last_name')}
      </p>
      <p className={styles.userEmail}>{me.get('email')}</p>
      <p className={styles.userPermission}>
        <FormattedMessage {...Messages[me.get('role')]} />
      </p>
      {impersonateAlertOpen && (
        <button className={styles.cancelImpersonateButton} onClick={cancelImpersonate}>
          <FormattedMessage {...Messages.cancelImpersonate} />
        </button>
      )}
    </div>
    <div id="navigation">
      {!isLeader && (
        <>
          <SideBarLink to="services" id="manageServicesButton" message={Messages.servicesLink} />
          <SideBarLink to="partners" id="managePartnersButton" message={Messages.partnersLink} />
        </>
      )}
      <SideBarLink to="users" id="manageUsersButton" message={Messages.usersLink} />
      {!isLeader && (
        <>
          <SideBarLink to="companies" id="manageCompaniesButton" message={Messages.companiesLink} />
          <SideBarLink to="reports" id="reportsButton" message={Messages.reportsLink} />
          <SideBarLink to="spaces" id="spacesButton" message={Messages.spacesLink} />
          <div className={styles.separator} />
          {isGlobalAdmin && (
            <div>
              <div className={styles.sectionTitleContainer}>
                <span className={styles.sectionTitle}>
                  <FormattedMessage {...Messages.globalAdminTools} />
                </span>
              </div>
              <div>
                <SideBarLink
                  to="admins"
                  id="manageAdminsButton"
                  message={Messages.manageAdminsLink}
                />
                <SideBarLink
                  to="campuses"
                  id="manageCampusesButton"
                  message={Messages.manageCampusesLink}
                />
                <SideBarLink
                  to="regions"
                  id="manageRegionsButton"
                  message={Messages.manageRegionsLink}
                />
                <SideBarLink
                  to="promocodes"
                  id="managePromoCodesButton"
                  message={Messages.managePromoCodesLink}
                />
                <SideBarLink
                  to="globalcategories"
                  id="manageCategoriesButton"
                  message={Messages.manageGlobalCategoriesLink}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className={styles.separator} />
      <SideBarLink to="logout" id="logoutButton" message={Messages.logOut} onClick={logout} />
    </div>
  </Drawer>
);

SideBar.propTypes = {
  me: PropTypes.object,
  isGlobalAdmin: PropTypes.bool,
  open: PropTypes.bool,
  impersonateAlertOpen: PropTypes.bool,
  cancelImpersonate: PropTypes.func,
  close: PropTypes.func,
  logout: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  me: makeSelectMyData(),
  isGlobalAdmin: makeSelectIsGlobalAdmin(),
  isLeader: MeSelectors.isLeader(),
});

export default connect(mapStateToProps)(SideBar);
