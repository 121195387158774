/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

/** External Dependencies **/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/LocalizationUtils';

/** Internal Dependencies **/
import {
  closeModal,
  setSidebar,
  FetchMeActions,
  LogoutActions,
  cancelAdminUserImpersonate,
  checkImpersonate,
} from 'zo-data-layer/actions';
import { MeSelectors, makeSelectModalState, makeSelectSidebarState } from 'zo-data-layer/selectors';

/** Components **/
import Toast from '../Toast';
import withRemoveLoader from '../withRemoveLoader';
import SideBar from '../../components/SideBar';

/** Assets **/
import closeIcon from '../../component-library/images/icons/icon-close.svg';

/** Styling **/
import styles from './style.module.scss';

/** Internal Dependencies **/
import Messages from './Messages';

class App extends PureComponent {
  static propTypes = {
    isMeLoaded: PropTypes.bool.isRequired,
    children: PropTypes.node,
    sidebarLayout: PropTypes.object,
    logout: PropTypes.func,
    setSidebar: PropTypes.func,
    fetchMe: PropTypes.func,
    cancelAdminUserImpersonate: PropTypes.func,
    checkImpersonate: PropTypes.func,
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    this.props.setSidebar(true);
    this.props.fetchMe();
    this.props.checkImpersonate();
  }

  render() {
    const { isMeLoaded } = this.props;
    const closeSidebar = this.props.setSidebar.bind(null, false);
    const openSidebar = this.props.setSidebar.bind(null, true);
    const sideBarOpen = this.props.sidebarLayout.get('open');
    const impersonateAlertOpen = this.props.sidebarLayout.get('impersonate');

    return (
      isMeLoaded && (
        <div className={styles.appWrapper}>
          <Helmet defaultTitle="ZO." />
          <SideBar
            close={closeSidebar}
            open={sideBarOpen}
            impersonateAlertOpen={impersonateAlertOpen}
            cancelImpersonate={this.props.cancelAdminUserImpersonate}
            logout={this.props.logout}
          />
          <div className={`${styles.contentWrapper} ${sideBarOpen ? styles.open : styles.close}`}>
            <button className={styles.menu} onClick={openSidebar}>
              <img
                className={styles.icon}
                alt={this.props.intl.formatMessage(Messages.menuAlt)}
                src={closeIcon}
              />
            </button>
            {this.props.children}
          </div>
          <Toast />
        </div>
      )
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isMeLoaded: MeSelectors.isSuccess(),
  sidebarLayout: makeSelectSidebarState(),
  modalLayout: makeSelectModalState(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMe: FetchMeActions.request,
      setSidebar,
      closeModal,
      logout: LogoutActions.request,
      cancelAdminUserImpersonate,
      checkImpersonate,
    },
    dispatch
  );

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withRemoveLoader
)(App);
