/*
 *
 * Loading Bar
 *
 */

/** External Dependencies **/
import React, { Component } from 'react';

/** Styling **/
import styles from './style.module.scss';

class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = { percent: 0 };

    this.increment = this.increment.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(this.increment, 75);
  }

  componentWillUnmount() {
    // cleaning up interval.
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  increment() {
    /**
     * Increment the percent randomly.
     */
    let { percent } = this.state;
    percent += Math.random() + 1 - Math.random();

    if (percent < 99) {
      this.setState({ percent });
    } else {
      this.setState(
        {
          percent: 99.1,
        },
        () => {
          this.timeout = setTimeout(() => {
            this.setState({ percent: 0 });
          }, 500);
        }
      );
    }
  }

  render() {
    const { percent } = this.state;

    // Hide progress bar if percent is less than 0.
    const isHidden = percent < 0 || percent >= 100;

    // Set `state.percent` as width.
    const style = { width: `${percent <= 0 ? 0 : percent}%` };
    return (
      <div className={`${styles.wrapper} ${isHidden && styles.hidden}`}>
        <div className={styles.bar} style={style}></div>
      </div>
    );
  }
}

export default ProgressBar;
