import React from 'react';
import PropTypes from 'prop-types';

const ReportsIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm1 18h2v-6H7v6zm4 0h2v-8h-2v8zm4 0h2v-4h-2v4z"
      />
    </g>
  </svg>
);

ReportsIcon.propTypes = {
  color: PropTypes.string,
};

export default ReportsIcon;
