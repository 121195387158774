import React from 'react';
import PropTypes from 'prop-types';

const PartnersIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M9.744 10.5A4.502 4.502 0 0 0 1 12a4.5 4.5 0 0 0 8.744 1.5h4.512A4.502 4.502 0 0 0 23 12a4.5 4.5 0 0 0-8.744-1.5H9.744z"
      />
    </g>
  </svg>
);

PartnersIcon.propTypes = {
  color: PropTypes.string,
};

export default PartnersIcon;
