import ReactGA from 'react-ga';

import { PROD_GA_TOKEN, STAGE_GA_TOKEN } from '../constants/analytics';

class AnalyticsReporter {
  constructor() {
    switch (process.env.APP_ENV) {
      case 'production':
        this.reporter = new GAReporter(PROD_GA_TOKEN);
        break;
      case 'staging':
        this.reporter = new GAReporter(STAGE_GA_TOKEN);
        break;
      default:
        this.reporter = new DevAnalyticsReporter();
        break;
    }
  }

  logPageView() {
    this.reporter.logPageView();
  }

  logEvent(params) {
    this.reporter.logEvent(params);
  }

  setDimension() {
    //nothing, here for legacy purposes with sagas.
  }
}

class GAReporter {
  constructor(GA_CODE) {
    ReactGA.initialize(GA_CODE);
    ReactGA.set({ anonymizeIp: true });
  }

  logPageView() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }

  logEvent({ category, action, label, value }) {
    const event = { category, action, label, value };
    Object.keys(event).forEach((key) => event[key] == null && delete event[key]);

    ReactGA.event(event);
  }
}

class DevAnalyticsReporter {
  logPageView() {
    console.log('Analytics', 'Tracking page view', window.location.pathname);
  }

  logEvent(params) {
    console.log('Analytics', 'Tracking event', params);
  }
}

export default new AnalyticsReporter();
