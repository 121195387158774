import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

/* Constants */
import { SIDEBAR_ICONS } from '../../constants/sidebar';

/** Styling **/
import styles from './style.module.scss';

const SideBarIcon = ({ active, to }) => {
  const Icon = SIDEBAR_ICONS[to];
  const color = active ? '#20556c' : '#AEB0B8';
  return <Icon color={color} />;
};

SideBarIcon.propTypes = {
  active: PropTypes.bool,
  to: PropTypes.string,
};

const SideBarLink = ({ to, id, message, onClick, router }) => {
  const route = router.location.pathname.split('/');
  const section = route[1];
  return (
    <Link
      to={`/${to}`}
      className={styles.linkContainer}
      id={id}
      activeClassName={styles.linkContainerActive}
      onClick={onClick}
    >
      <SideBarIcon to={to} active={to === section} />
      <span className={styles.linkText}>
        <FormattedMessage {...message} />
      </span>
    </Link>
  );
};

SideBarLink.propTypes = {
  to: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  router: PropTypes.object,
};

export default withRouter(SideBarLink);
