/*
 * Analytics Constants
 */

/** Error Reporting **/
export const SENTRY_DSN =
  'https://0eacd990a02f6a6572f82c6c4d5c15e3@o4506418083463168.ingest.sentry.io/4506706860572672';

/** Google Analytics **/
export const STAGE_GA_TOKEN = '';
export const PROD_GA_TOKEN = '';
