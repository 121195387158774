/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import * as dataLayerTranslations from 'zo-data-layer/translations';

import { DEFAULT_LOCALE } from './constants/locale';

import AppEn from './translations/en.json';
import AppDe from './translations/de.json';
import AppFr from './translations/fr.json';
import AppPt from './translations/pt.json';

const enTranslationMessages = { ...dataLayerTranslations.En, ...AppEn };
const frTranslationMessages = { ...dataLayerTranslations.Fr, ...AppFr };
const deTranslationMessages = { ...dataLayerTranslations.De, ...AppDe };
const ptTranslationMessages = { ...dataLayerTranslations.Pt, ...AppPt };

export const appLocales = ['de', 'en', 'fr', 'pt'];

export const languageNameI18nIds = appLocales.reduce(
  (acc, id) => ({ ...acc, [id]: `zo.app.languages.${id}` }),
  {}
);

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  de: formatTranslationMessages('de', deTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
};
