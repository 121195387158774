import { defineMessages } from 'react-intl';

export default defineMessages({
  headingText: {
    id: 'zo.app.containers.LoadingBar.headingText',
  },
  bodyText: {
    id: 'zo.app.containers.LoadingBar.bodyText',
  },
});
