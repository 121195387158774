import React from 'react';

const AccountsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M16 1C7.72 1 1 7.72 1 16c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm0 4.5c2.49 0 4.5 2.01 4.5 4.5s-2.01 4.5-4.5 4.5-4.5-2.01-4.5-4.5 2.01-4.5 4.5-4.5zm0 21.3a10.8 10.8 0 0 1-9-4.83c.045-2.985 6-4.62 9-4.62 2.985 0 8.955 1.635 9 4.62a10.8 10.8 0 0 1-9 4.83z"
    />
  </svg>
);

export default AccountsIcon;
